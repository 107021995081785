<template>
  <div class="body-content">
    <!-- 大图、3数据、动态更新 -->
    <div style="padding: 35px 0px;">
      <div class="header-wrapper">
        <!-- <el-image :src="require('@/static/images/polic-trains.png')" class="left" fit="cover" /> -->
        <div class="left">
          <banner v-if="bannerList&&bannerList.length>0" :homeBanner="bannerList"></banner>
        </div>
        <div class="right">
          <div class="three_data_wrapper bg-rgba-w-1">
            <num-data :num="countryPolicyCount" :type="'国家政策'" @handleClick="goGuojia"></num-data>
            <div class="divider"></div>
            <num-data :num="provincePolicyCount" :type="'省级政策'" @handleClick="goSheng"></num-data>
            <div class="divider"></div>
            <num-data :num="cityPolicyCount" :type="'市级政策'" @handleClick="goShi"></num-data>
          </div>
          <section-title title="动态信息" style="margin-bottom: 16px;"></section-title>
          <div class="dynamic-wrapper bg-rgba-w-1">
            <div
              style="text-align:right;cursor: pointer;line-height: 30px;"
              @click="goDynamicList()"
            >更多>></div>
            <point-title
              v-for="item in informationList"
              :title="item.title"
              :time="item.time ? item.time.substring(0, 10) : '--'"
              @goDetail="goDynamicList(item.id)"
              :key="item.title"
            ></point-title>
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索框 -->
    <long-search @search="search" style="margin: 40px 0;"></long-search>
    <!-- 国家政策 -->
    <section-title title="国家政策"></section-title>
    <div class="horizontal_1_1_wrapper">
      <div
        class="item"
        style="display: flex;flex-direction: column;justify-content: space-between;"
      >
        <article-list-thumn
          class="item"
          title="国家科技法律法规"
          :datas="countryMap.countryLaw.slice(0, 5)"
          @handleClick="(id) => { goGuojia('falvfagui', id) }"
        ></article-list-thumn>
        <div class="divider-v"></div>
        <article-list-thumn
          class="item"
          title="科技政策"
          :datas="countryMap.countryPolicy"
          @handleClick="(id) => { goGuojia('kejizhengce', id) }"
        ></article-list-thumn>
      </div>
      <div class="divider-h"></div>
      <div class="item">
        <article-list-thumn
          title="科技政策解读"
          :datas="countryMap.countryPolicyDecode"
          :maxCount="15"
          @handleClick="(id) => { goGuojia('kejizhengcejiedu', id) }"
        ></article-list-thumn>
      </div>
    </div>
    <!-- 省政策 -->
    <section-title title="四川省科学技术政策"></section-title>
    <div class="horizontal_1_1_wrapper">
      <article-list-thumn
        class="item"
        title="科技政策"
        :maxCount="6"
        :datas="provinceMap.provincePolicy"
        @handleClick="(id) => { goSheng('kejizhengce', id) }"
      ></article-list-thumn>
      <div class="divider-h"></div>
      <article-list-thumn
        class="item"
        title="政策解读"
        :maxCount="6"
        :datas="provinceMap.provincePolicyDecode"
        @handleClick="(id) => { goSheng('zhengcejiedu', id) }"
      ></article-list-thumn>
    </div>
    <!-- 市政策 -->
    <section-title title="绵阳市科学技术局政策"></section-title>
    <div class="horizontal_1_1_wrapper">
      <article-list-thumn
        class="item"
        title="科技政策"
        :maxCount="6"
        :datas="cityMap.cityPolicy"
        @handleClick="(id) => { goShi('kejizhengce', id) }"
      ></article-list-thumn>
      <div class="divider-h"></div>
      <article-list-thumn
        class="item"
        title="政策解读"
        :maxCount="6"
        :datas="cityMap.cityPolicyDecode"
        @handleClick="(id) => { goShi('zhengcejiedu', id) }"
      ></article-list-thumn>
    </div>
  </div>
</template>

<script>
import sectionTitle from './components/section-title.vue'
import numData from './components/num-data.vue'
import articleListThumn from './components/article-list-thumb.vue'
import longSearch from '@/components/long-search.vue'
import pointTitle from '@/components/point-article-title.vue'
import Banner from '@/components/banner.vue';

export default {
  name: 'policy-index',
  components: {
    sectionTitle,
    numData,
    articleListThumn,
    longSearch,
    pointTitle,
    Banner
  },
  data() {
    return {
      pageTopPosition: 0,
      cityPolicyCount: '',
      countryPolicyCount: '',
      provincePolicyCount: '',
      informationList: [],
      countryMap: {
        'countryLaw': []
      },
      provinceMap: {},
      cityMap: {},
      bannerList: [],
    }
  },
  beforeRouteLeave(to, from, next) {
    this.pageTopPosition = document.getElementById('app').scrollTop
    next()
  },
  activated() {
    document.getElementById('app').scrollTop = this.pageTopPosition
  },
  created() {
    // 调用工具方法示例
    console.log(this.$util.testName());
  },
  mounted() {
    this.getIndexInfo()
  },
  methods: {
    getIndexInfo() {
      this.$api.policyHall.index().then((res) => {
        //console.log('res = ', res)
        const data = res.data.data
        if (data) {
          this.bannerList = data.bannerList
          this.informationList = data.informationList
          this.countryMap = data.countryMap
          this.provinceMap = data.provinceMap
          this.cityMap = data.cityMap
          this.countryPolicyCount = data.countryPolicyCount
          this.provincePolicyCount = data.provincePolicyCount
          this.cityPolicyCount = data.cityPolicyCount
        }
      })
    },
    search(v) {
      console.log('search key = ', v)
      this.$router.push('/policyHall/page-search?keywords=' + v)
    },
    goDynamicList(id) {
      console.log('id = ', id)
      if (id) {
        this.$router.push({ path: '/policyHall/page-dongtai/articledetail', query: { id: id, 'detailType': 'policyHall-dongtai' } })
      }
      else {
        this.$router.push('/policyHall/page-dongtai')
      }
    },
    goGuojia(menu_id, id) {
      if (id) {
        this.$router.push({ path: '/policyHall/page-guojia/articledetail', query: { id: id, 'detailType': 'policyHall-policy' } })
      }
      else {
        this.$router.push('/policyHall/page-guojia?menu_id=' + menu_id)
      }
    },
    goSheng(menu_id, id) {
      if (id) {
        this.$router.push({ path: '/policyHall/page-sheng/articledetail', query: { id: id, 'detailType': 'policyHall-policy' } })
      }
      else {
        this.$router.push('/policyHall/page-sheng?menu_id=' + menu_id)
      }
    },
    goShi(menu_id, id) {
      if (id) {
        this.$router.push({ path: '/policyHall/page-shi/articledetail', query: { id: id, 'detailType': 'policyHall-policy' } })
      }
      else {
        this.$router.push('/policyHall/page-shi?menu_id=' + menu_id)
      }
    },
  }
}
</script>
<style scoped lang="less">
/deep/.swiper-container {
  height: 100% !important;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  height: 450px;

  .left {
    // flex: 1;
    flex-shrink: 0;
    margin-right: 30px;
    min-width: 100px;
    width: 670px;
    height: 100%;
    overflow: hidden;
  }

  .right {
    flex: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    min-width: 100px;

    .three_data_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      height: 90px;
      padding: 0 55px;

      .divider {
        width: 2px;
        height: 80%;
        background-color: #7d7d7d;
      }
    }

    .dynamic-wrapper {
      flex: 1;
      padding: 22px 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .item-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-height: 60px;
        overflow: hidden;
        cursor: pointer;

        .point {
          width: 11px;
          height: 11px;
          background-color: #fff;
          border-radius: 5.5px;
          margin-right: 35px;
        }

        .title {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .time {
          min-width: 30px;
        }
      }
    }
  }
}

.horizontal_1_1_wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  .item {
    flex: 1;
    flex-shrink: 0;
    min-width: 10px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  .divider-h {
    min-width: 47px;
  }

  .divider-v {
    min-height: 27px;
  }
}
</style>
