import { render, staticRenderFns } from "./point-article-title.vue?vue&type=template&id=0d2ee1a8&scoped=true&"
import script from "./point-article-title.vue?vue&type=script&lang=js&"
export * from "./point-article-title.vue?vue&type=script&lang=js&"
import style0 from "./point-article-title.vue?vue&type=style&index=0&id=0d2ee1a8&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d2ee1a8",
  null
  
)

export default component.exports