<template>
	<!-- 蓝色标题+下划线 -->
	<div class="number-data-wrapper" @click="goList">
		<span class="num">{{num}}<span class="subtitle h2"> 条</span> </span>
		<div class="h2">{{type}}</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {}
		},
		props: ['num','type'],
		mounted() {

		},
		methods: {
			goList(){
				this.$emit('handleClick')
			},
		}
	}
</script>
<style scoped lang="less">
	.number-data-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		.num {
			font-size: 30px;
			color: #ECFA0B;
		}
	}
</style>
