<template>
  <!-- 首页，最近政策列表 -->
  <div class="thumbs-wrapper">
    <!-- 标题、更多 -->
    <div class="header">
      <div class="title h1">{{title}}</div>
      <div class="more" @click="moreClick">更多>></div>
    </div>
    <point-title
      v-for="item in datas"
      :title="item.title"
      :time="item.time?item.time.substring(0,10):'--'"
      @goDetail="singleArticelDetail(item.id)"
      pointColor="#F39800"
      :key="item.id"
    ></point-title>
  </div>
</template>

<script>
import pointTitle from '@/components/point-article-title.vue'
export default {
  components: {
    pointTitle
  },
  data() {
    return {
    }
  },
  props: ['title', 'datas'],
  mounted() {

  },
  methods: {
    moreClick() {
      this.$emit('handleClick')
    },
    singleArticelDetail(id) {
      this.$emit('handleClick', id)
    },
  }
}
</script>
<style scoped lang="less">
.thumbs-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 5px 20px 20px 20px;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;

    .title {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .more {
      min-width: 70px;
      cursor: pointer;
    }
  }

  .item-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    height: 50px;

    .point {
      width: 11px;
      height: 11px;
      background-color: #f39800;
      border-radius: 5.5px;
      margin-right: 35px;
    }

    .title {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }

    .time {
      min-width: 130px;
    }
  }
}
</style>
