<template>
  <div class="point-article-title-wrapper h2" @click="goDetail">
    <div class="point" :style="pointStyle" v-if="hidePoint!==true"></div>
    <div class="title">{{title}}</div>
    <div class="time">{{time}}</div>
  </div>
</template>

<script>
export default {
  props: ['title', 'time', 'pointColor', 'hidePoint'],
  data() {
    return {}
  },
  computed: {
    pointStyle() {
      if (this.pointColor) {
        return {
          'background-color': this.pointColor
        }
      }
      return {
        'background-color': '#fff'
      }
    },
  },
  created() { },
  mounted() {

  },
  methods: {
    goDetail() {
      this.$emit('goDetail')
    },
  }
}
</script>

<style scoped lang="less">
.point-article-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  // max-height: 60px;
  overflow: hidden;
  cursor: pointer;
  height: 40px;
  .point {
    width: 8px;
    height: 8px;
    background-color: #fff;
    border-radius: 4px;
    margin-right: 20px;
  }

  .title {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .time {
    min-width: 130px;
    margin-left: 20px;
    text-align: right;
  }
}
</style>
